import './CookiesBanner.css';
import React, { useState } from 'react';

export const CookiesBanner = () => {
    let storedConsent = localStorage.getItem("COOKIES_CONSENT");
    const [isClosed, setIsClosed] = useState(storedConsent !== null && storedConsent);

    function close() {
        setIsClosed(true);
        localStorage.setItem("COOKIES_CONSENT", true);
    }

    if (isClosed) {
        return null;
    }

    return (
        <div className="cookies-banner">
            <div className="cookies-banner-content">
                <p>
                    Strona korzysta z plików cookies. Możesz określić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.
                </p>
                <button onClick={() => { close(); }}>Rozumiem</button>
            </div>
        </div>
    );
}