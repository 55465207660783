import './ProjectDetailsImagesCollection.css';
import ImageGallery from "react-image-gallery";

export const ProjectDetailsImagesCollection = ({ images, type, name }) => {

    const imagesWithDetails = images.map(i => Object.create({
        original: require(`../../../../assets/page-content/images/${type}/${name}/${i.normal}`),
        thumbnail: require(`../../../../assets/page-content/images/${type}/${name}/${i.miniature}`)
    }));

    return (
        <div className="project-details-image-collection">
            <ImageGallery items={imagesWithDetails} autoPlay={false} showPlayButton={false} showNav={true} />
        </div>
    );
}
