import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import { App } from './App';
import { ErrorPage } from './pages/error-page/ErrorPage';
import { About } from './pages/about/About';
import { MainGallery } from './pages/main-gallery/MainGallery';
import { ProjectDetails } from './pages/project-details/ProjectDetails';
import { CookiesBanner } from './components/menu-bar/cookies-banner/CookiesBanner';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "architektura",
    element: <MainGallery type="architecture" />
  },
  {
    path: "architektura/:projectName",
    element: <ProjectDetails type="architecture" />
  },
  {
    path: "wnetrza",
    element: <MainGallery type="indoors" />
  },
  {
    path: "wnetrza/:projectName",
    element: <ProjectDetails type="indoors" />
  },
  {
    path: "firma",
    element: <About />
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <CookiesBanner />
  </React.StrictMode>
);
