import './ErrorPage.css';
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="center error-page">
      <h1>Ups!</h1>
      <p>Nie znaleziono danej podstrony</p>
      <button type="button" onClick={() => { navigate('/'); }}>Wróć do strony głównej</button>
    </div>
  );
}