import './MainGallery.css';
import { MenuBar } from '../../components/menu-bar/MenuBar';
import { MainGalleryImage } from './main-gallery-image/MainGalleryImage';

export const MainGallery = ({ type }) => {
  return (
    <div className="main-gallery">
      <MenuBar />
      {getElements(type).map(elem =>
        <MainGalleryImage key={elem.name} element={elem} type={type} />)}
    </div>
  );
}

function getElements(type) {
  var pageContent = require('../../assets/page-content/page-content.json');

  switch (type) {
    case 'architecture':
      return pageContent.architecture;
    case 'indoors':
      return pageContent.indoors;
    default:
      return undefined;
  }
}