import './MenuBar.css';
import { IoHomeOutline, IoBedOutline, IoBriefcaseOutline } from "react-icons/io5";
import { MainButton } from './main-button/MainButton';

export const MenuBar = () => {
    return (
        <div className="menu-bar-buttons">
            <MainButton title="FIRMA" route="/firma" icon={<IoBriefcaseOutline size={50} />} />
            <MainButton title="ARCHITEKTURA" route="/architektura" icon={<IoHomeOutline size={50} />} />
            <MainButton title="WNĘTRZA" route="/wnetrza" icon={<IoBedOutline size={50} />} />
        </div>
    );
}