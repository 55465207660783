import './MainButton.css';
import { useNavigate } from "react-router-dom";

export const MainButton = ({ title, icon, route }) => {
  const navigate = useNavigate();

  return (
    <div className="main-btn" onClick={() => { navigate(route); }}>
      <div className="m-btn-element m-btn-title ">{title}</div>
      <div className="m-btn-element m-btn-icon">{icon}</div>
    </div>
  );
}