import { ProjectDetailsImagesCollection } from './project-details-images-collection/ProjectDetailsImagesCollection';
import { ProjectDetailsSingleImage } from './project-details-single-image/ProjectDetailsSingleImage';
import './ProjectDetailsElement.css';

export const ProjectDetailsElement = ({ element, name, type }) => {

    if (element.images !== undefined) {
        return (
            <ProjectDetailsImagesCollection images={element.images} type={type} name={name} />
        );
    }
    else if (element.singleImage !== undefined) {
        return (
            <ProjectDetailsSingleImage element={element} type={type} name={name} />
        );
    }
    return (
        <div> Brak treści </div>
    );
}