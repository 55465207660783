import './ProjectDetails.css';
import { useParams } from "react-router-dom";
import { MenuBar } from '../../components/menu-bar/MenuBar';
import { ErrorPage } from '../../pages/error-page/ErrorPage';
import { ProjectDetailsElement } from './project-details-element/ProjectDetailsElement';

export const ProjectDetails = ({ type }) => {
    let { projectName } = useParams();
    let elementDetails = getElementDetails(type, projectName);

    if (elementDetails === undefined) {
        return (
            <ErrorPage />
        );
    }

    let elemsCounter = 0;

    return (
        <div className="project-details">
            <MenuBar />
            {
                elementDetails.map(e =>
                    <ProjectDetailsElement key={++elemsCounter} element={e} name={projectName} type={type} />
                )
            }
        </div>
    );
}

function getElementDetails(type, projectName) {
    let elements = getElements(type);
    let element = elements.find(e => e.name === projectName);
    return element?.details;
}

function getElements(type) {
    var pageContent = require('../../assets/page-content/page-content.json');

    switch (type) {
        case 'architecture':
            return pageContent.architecture;
        case 'indoors':
            return pageContent.indoors;
        default:
            return undefined;
    }
}
