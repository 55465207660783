import './App.css';
import { MenuBar } from './components/menu-bar/MenuBar';
import logo from './assets/general/main-menu.png';

export const App = () => {
  return (
    <div className="app-container">
      <MenuBar />
      <img className="app-picture" src={logo} alt="logo" />
    </div>
  );
}
