import './MainGalleryImage.css';
import { useNavigate } from "react-router-dom";

export const MainGalleryImage = ({ element, type }) => {
    const hasDetails = element.details !== undefined;
    const navigate = useNavigate();

    return (
        <div className={hasDetails ? 'main-gallery-img-with-more-content' : ''} onClick={() => { if (hasDetails) navigate(element.name) }}>
            <img src={require(`../../../assets/page-content/images/${type}/${element.name}/${element.mainImage}`)} className="main-gallery-img" alt="main"></img>
        </div>
    );
}