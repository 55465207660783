import './About.css';
import { MenuBar } from '../../components/menu-bar/MenuBar';
import logo from '../../assets/general/logo.jpg';
import img1 from '../../assets/page-content/images/architecture/DJ_10/DJ_10_1.jpg';
import img2 from '../../assets/page-content/images/architecture/W_01/W_01_1M.jpg';
import img3 from '../../assets/page-content/images/indoors/PWN_01/PWN_01_1.jpg';
import portrait from '../../assets/general/portrait.jpg';
import img5 from '../../assets/page-content/images/architecture/sh/SH.jpg';
import img6 from '../../assets/page-content/images/architecture/DJ_12/DJ_12_1M.jpg';

export const About = () => {
  return (
    <div className="about">
      <MenuBar />
      <div className="about-content">
        <div className="about-title">Pracownia Architektoniczna Sebastian Krawczyk</div>
        <div><img className="about-logo" src={logo} alt="logo" /></div>
        <div className="about-elem"><a className="about-email" href="mailto:biuro@krawczyksebastian.pl">biuro@krawczyksebastian.pl</a></div>
        <div className="about-text">NIP: 5742074120</div>
        <div className="about-pictures">
          <img className="about-picture" src={img1} alt="about" />
          <img className="about-picture" src={img2} alt="about" />
          <img className="about-picture" src={img3} alt="about" />
        </div>
        <div className="about-text">Pracownia, dla której ważnie są emocje ludzi, a ich marzenia odzwierciedla architektura. Pracownia, gdzie architektura to sztuka łączenia formy z funkcją, a każdy projekt jest wyrazem harmonii między estetyką, a praktycznością. Gdzie doskonałe połączenie elegancji i funkcjonalności – to klucz do tworzenia przestrzeni, które inspirują i służą przez lata.</div>
        <div className="about-pictures">
          <img className="about-picture" src={portrait} alt="about" />
          <img className="about-picture" src={img5} alt="about" />
          <img className="about-picture" src={img6} alt="about" />
        </div>
        <div className="about-text italic">"Specjalizuję się w projektowaniu nowoczesnych budynków oraz stylowych wnętrz, które odpowiadają na unikalne potrzeby moich klientów. Każdy projekt to indywidualne wyzwanie – od koncepcji, przez projekt budowlany, wykonawczy po realizację. Zawsze dbam o spójność wizji, równowagę przestrzeni z troską o każdy etap procesu projektowego. Tworzenie przestrzeni funkcjonalnych i ponadczasowych, dopasowanych do codziennego życia, a jednocześnie ciekawych w formie to wyzwania jakie podejmuję z każdym projektem. Współpracuję z zaufanymi firmami, co gwarantuje wysoką jakość projektów. Moje usługi obejmują pełny zakres, od budynków mieszkalnych jednorodzinnych, wielorodzinnych po usługowe i wnętrza."</div>
      </div>
    </div>
  );
}